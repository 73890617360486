<template>
  <div class="product-serive-detail">
    <div style="height: 100%">
      <el-table :data="tableList" height="100%" :header-cell-style="{ fontWeight: 'normal', height: '60px', color: '#666', background: '#F6F6F6', fontSize: '16px' }" :cell-style="{ fontSize: '16px', color: '#333', height: '60px' }">
        <el-table-column prop="type" label="考试内容"> </el-table-column>
        <el-table-column prop="name" label="套餐名称"></el-table-column>
        <el-table-column prop="stu_good_title" label="学生答案">
          <template slot-scope="scope">
            <div v-if="scope.row.is_correct">{{ scope.row.stu_good_title && scope.row.stu_good_title.length > 10 ? scope.row.stu_good_title.slice(0, 10) + "..." : scope.row.stu_good_title }}</div>
            <div v-else style="color: #ff4040">{{ scope.row.stu_good_title && scope.row.stu_good_title.length > 10 ? scope.row.stu_good_title.slice(0, 10) + "..." : scope.row.stu_good_title }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="right_good_title" label="正确答案">
          <template slot-scope="scope">
            <div>{{ scope.row.right_good_title && scope.row.right_good_title.length > 10 ? scope.row.right_good_title.slice(0, 10) + "..." : scope.row.right_good_title }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="score" align="right" label="得分" width="100"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { serviceManagementData } from "@/utils/productService";

export default {
  name: "ProductSeriveDetail",
  components: {},
  props: {},
  data() {
    return {
      tableList: [],
    };
  },
  computed: {},
  methods: {
    getTableList() {
      serviceManagementData({ exam_id: this.$route.query.examId, user_id: this.$route.query.id }).then((res) => {
        this.tableList = res.data.list;
        let goodsList = [];
        this.tableList.map((item, index) => {
          if (!index) item.type = "产品及服务组合信息管理";
          if (item.answer.length) item.right_good_title = item.answer[0].good_title;
          if (item.stu_answer.length) {
            item.stu_good_title = item.stu_answer[0].good_title;
            item.is_correct = item.stu_answer[0].is_correct;
          }
          goodsList.push(item);
          if (item.answer.length > item.stu_answer.length) {
            item.answer.map((right, sort) => {
              if (sort > 0) goodsList.push({ right_good_title: right.good_title, stu_good_title: item.stu_answer[sort] ? item.stu_answer[sort].good_title : "", is_correct: item.stu_answer[sort] ? item.stu_answer[sort].is_correct : "" });
            });
          } else {
            item.stu_answer.map((answer, sort) => {
              if (sort > 0) goodsList.push({ right_good_title: item.answer[sort] ? item.answer[sort].good_title : "", stu_good_title: answer.good_title, is_correct: answer.is_correct });
            });
          }
        });
        this.tableList = goodsList;
      });
    },
  },
  mounted() {
    this.getTableList();
  },
};
</script>

<style scoped lang="scss">
.product-serive-detail {
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  ::v-deep .gutter {
    background: #f6f6f6;
  }
}
</style>
